
<div class="header" id="header">

    <img class="logo" src="https://content.justinschaaf.com/common/logos/js-stylesheet512.png" height="128" width="128" routerLink="/">

    <h1 class="title">Justin's Reference Stylesheet</h1>
    <p class="heading-desc">
        This is a color palette with a wide range of different colors from my various projects or
        just colors that look nice. Feel free to use any of them. A link back here would be appreciated 😊.
    </p>

    <br>
  
</div>

<main class="content">
    <router-outlet></router-outlet>
</main>

<div class="footer" id="footer">

    <br>

    <div class="links">
        
        <!--<div class="link">
            <a href="https://www.github.com/justinhschaaf/ColorFormatter" class="footer-link">Color Formatting Tool</a>
        </div>-->

        <div class="link">
            <a href="https://www.github.com/justinhschaaf/ReferenceStylesheet" class="footer-link">Source</a>
        </div>

        <!--<div class="link">
            <a href="#header" class="footer-link">TOP</a>
        </div>-->

        <div class="link">
            <a href="https://justinschaaf.com" class="footer-link">Main Site</a>
        </div>

        <div class="link">
            <a href="https://liberapay.com/justinhschaaf" class="footer-link">Donate</a>
        </div>

    </div>

    <br>

</div>
